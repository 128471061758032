import { defineStore, getActivePinia } from 'pinia';
import { getBrands, getTags, getInfluencer, getColours } from '../services/base';
// import { colors } from './color';

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len) throw new RangeError('getRandom: more elements taken than available');
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
function groupIt(arr) {
  var obj = {};
  arr.forEach(
    (e) =>
      (obj[e[0].toUpperCase()] = arr.filter((ele) => ele[0].toUpperCase() == e[0].toUpperCase()))
  );
  return (obj);

}

// function removeItemOnce(arr, value) {
//   var index = arr.indexOf(value);
//   if (index > -1) {
//     arr.splice(index, 1);
//   }
//   return arr;
// }


function order(obj){
    Object.keys(obj).sort().reduce(
  (obj, key) => { 
    obj[key] = obj[key]; 
    return obj;
  }, 
  {}
);
}



function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

const useFilterValues = defineStore('filtervalues', {
  state: () => ({
    allBrands: [],
    brands: [],
    selectedBrands: [],
    ///
    allTags: [],
    tags: [],
    selectedTags: [],
    ////
    allInfluencer: [],
    influencer: [],
    selectedInfluencer: [],
    ////
    allColours: [],
    colours: [],
    selectedColours: [],
    menuColours: [],
    colorDict: {},
    ///
    quality: 50,
    username: '',
    text: '',
    dirty: false,
  }),
  getters: {
    getBrands: (state) => state.brands,
    getSearchBrands: (state) => state.brands,
    getSelectedBrands: (state) => state.selectedBrands.toString(),
    /////
    getTags: (state) => state.tags,
    getSearchTags: (state) => state.tags,
    getSelectedTags: (state) => state.selectedTags.toString(),
    ///
    getInfluencer: (state) => state.influencer,
    getSearchInfluencer: (state) => state.influencer,
    getSelectedInfluencer: (state) => state.selectedInfluencer.toString(),
    ///
    getColour: (state) => state.colours,
    getSearchColour: (state) => state.colours,
    getSelectedColour: (state) => state.selectedColours.toString(),
    getMenuColour: (state) => state.menuColours,
    ///
    getQuality: (state) => state.quality,
    getUserName: (state) => state.username,
    getText: (state) => state.text,
  },
  actions: {
    async fetchBrands() {
      var result = await getBrands();
      this.allBrands = result.data;
      this.brands = groupIt(this.allBrands);
    },
    searchOnBrands(searchStr) {
      if (searchStr !== '') {
        this.brands = groupIt(
          this.allBrands.filter((e) => e.toLowerCase().includes(searchStr.toLowerCase()))
        );
      } else {
        this.brands = order( groupIt(this.allBrands))
      }
    },
    setSelectedBrands(selectedBrands) {
      this.selectedBrands = selectedBrands;
      this.dirty = true;
    },
    resetSelectedBrands() {
      this.selectedBrands = [];
    },
    ///
    async fetchTags() {
      var result = await getTags();
      console.log(result);
      this.allTags = result.data;
      this.tags = groupIt(this.allTags);
    },
    searchOnTags(searchStr) {
      if (searchStr !== '') {
        this.tags = groupIt(
          this.allTags.filter((e) => e.toLowerCase().includes(searchStr.toLowerCase()))
        );
      } else {
        this.tags =order( groupIt(this.allTags));
      }
    },
    setSelectedTags(selectedTags) {
      this.selectedTags = selectedTags;

      this.dirty = true;
    },
    resetSelectedTags() {
      this.selectedTags = [];
    },
    ////
    async fetchInfluencer() {
      var result = await getInfluencer();
      console.log(result);
      this.allInfluencer = result.data;
      this.influencer = groupIt(this.allInfluencer);
    },

    searchOnInfluencer(searchStr) {
      if (searchStr !== '') {
        this.influencer = groupIt(
          this.allInfluencer.filter((e) => e.toLowerCase().includes(searchStr.toLowerCase()))
        );
      } else {
        this.influencer = groupIt(this.allInfluencer);
      }
    },
    setSelectedInfluencer(selectedInfluencer) {
      this.selectedInfluencer = selectedInfluencer;

      this.dirty = true;
    },
    resetSelectedInfluencer() {
      this.selectedInfluencer = [];
    },
    ////
    async fetchColour() {
      var result = await getColours();
      // this.allColours = Object.keys(colors);
      // console.log(result.data.colours);
      // console.log(result)
      var color = {};
      result.data.forEach((e) => {
        color[e.name] = e.hex;
      });
      // console.log(color);
      // result.data.clours.forEach
      this.colorDict = color;
      this.allColours = Object.keys(color);
      this.colours = groupIt(this.allColours);
      this.setMenuColour();
    },

    searchOnColours(searchStr) {
      if (searchStr !== '') {
        this.colours = groupIt(
          this.allColours.filter((e) => e.toLowerCase().includes(searchStr.toLowerCase()))
        );
      } else {
        this.colours = groupIt(this.allColours);
      }
    },
    setSelectedColours(selectedColour) {
      this.dirty = true;
      this.selectedColours = this.selectedColours.concat(selectedColour);
    },
    resetSelectedColours() {
      this.selectedColours = [];
      this.setMenuColour();
    },
    setMenuColour() {
      var menu = [];
      if (this.selectedColours.length <= 8) {
        menu = getRandom(this.allColours, 8 - this.selectedColours.length);
        this.menuColours = this.selectedColours.concat(menu);
      } else {
        this.menuColours = getRandom(this.allColours, 8);
      }
    },
    addColor(color) {
      this.dirty = true;
      this.selectedColours.push(color);
    },
    removeColor(color) {
      this.selectedColours = removeItemAll(this.selectedColours, color);
    },

    ////
    setQuality(quality) {
      this.dirty = true;
      this.quality = quality;
    },
    setText(text) {
      this.dirty = true;
      this.text = text;
    },
    setUserName(username) {
      this.dirty = true;
      this.username = username;
    },
    resetQuality() {
      this.quality = 50;
    },
    resetText() {
      this.text = '';
    },
    resetUserName() {
      this.username = '';
    },

    resetAll() {
      this.resetUserName();
      this.resetText();
      this.resetQuality();
      this.resetSelectedColours();
      this.resetSelectedTags();
      this.resetSelectedBrands();
      this.resetSelectedInfluencer();
      this.resetSelectedColours();

      this.dirty = false;
    },
  },
});

export default useFilterValues;
