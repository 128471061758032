<template>
  <div class="main">
    <div class="">
      <div class="row">
        <div class="col">
          <div class="searchBox">
            <form name="registrationForm">
              <div class="formTitle mb-3">
                <p>Influencer Search</p>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Anything you want</label>

                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Type anything..."
                  v-model="text"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Social Handle</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Type social handle"
                  v-model="username"
                />
              </div>
              <div class="mb-3 range">
                <div>
                  <label for="customRange1" class="form-label">Quality</label>
                  <div class="counter">
                    <output name="ageOutputName" id="quality_range">{{ quality }}</output>
                    <p>-100</p>
                  </div>
                </div>
                <Slider v-model="quality" :tooltips="false" class="range" />
              </div>
              <div class="mb-3">
                <div>
                  <label for="exampleInputPassword1" class="form-label"
                    >Influencer Classification</label
                  >
                  <a href="#" v-on:click="store.toggleInfluencer()"
                    >View all
                    <img src="@/assets/Vector.svg" alt="vector" />
                  </a>
                  <!-- <router-link>View all</router-link> -->
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="alternative,art,athlete"
                  v-model="selectedInfluencer"
                />
              </div>
              <div class="mb-3">
                <div>
                  <label for="exampleInputPassword1" class="form-label">Tag</label>
                  <a href="#" v-on:click="store.toggleTag"
                    >View all
                    <img src="@/assets/Vector.svg" alt="vector" />
                  </a>
                  <!-- <router-link>View all</router-link> -->
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Type tag"
                  v-model="selectedTags"
                  v-on:change="showTags"
                />
                <div class="tagBox">
                  <div
                    v-once
                    class="tagBoxItems"
                    id="tagBoxItems"
                    style="cursor: pointer; background-color: #e8ddf3"
                    v-on:click="setTag('pet')"
                  >
                    <p>Pet</p>
                  </div>
                  <div
                    v-once
                    class="tagBoxItems"
                    id="tagBoxItems"
                    style="cursor: pointer; background-color: #b37dea"
                    v-on:click="setTag('cat')"
                  >
                    <p>Cat</p>
                  </div>
                  <div
                    v-once
                    class="tagBoxItems"
                    id="tagBoxItems"
                    style="cursor: pointer; background-color: #ddf3dd"
                    v-on:click="setTag('travel')"
                  >
                    <p>Travel</p>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div>
                  <label for="exampleInputPassword1" class="form-label">Brand</label>
                  <a href="#" v-on:click="store.toggleBrand"
                    >View all
                    <img src="@/assets/Vector.svg" alt="vector" />
                  </a>
                  <!-- <router-link>View all</router-link> -->
                </div>

                <input
                  type="text"
                  class="form-control"
                  placeholder="amazon,american express,apple"
                  v-model="selectedBrands"
                />
              </div>
              <div class="mb-5">
                <div>
                  <label for="exampleInputPassword1" class="form-label">Colour</label>
                  <a href="#" v-on:click="store.toggleColor"
                    >View all
                    <img src="@/assets/Vector.svg" alt="vector" />
                  </a>
                  <!-- <router-link>View all</router-link> -->
                </div>
                <div class="colorBox">
                  <div class="color_predefined">
                    <input
                      v-for="items in menuColours"
                      :key="items"
                      type="checkbox"
                      class="form-check-input"
                      id="form-checkbox-input1"
                      v-bind:style="{ 'background-color': colorDict[items] }"
                      :checked="checkColorSelected(items)"
                      v-on:change="changeCheckBox($event, items)"
                    />
                  </div>
                </div>
              </div>
              <div class="myBtn mt-4">
                <button
                  href="#"
                  type="button"
                  id="btnreset"
                  class="btn btn-reset"
                  :disabled="!reset"
                  v-on:click="resetButton"
                >
                  Reset
                </button>
                <button type="button" class="btn btn-search" v-on:click="search">Search</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import useFilterBoxShow from '@/store/filterbox';
import useFilterValues from '@/store/filterValues';
import { getActivePinia, storeToRefs } from 'pinia';
import useResult from '@/store/result';
import { useRouter } from 'vue-router';
import Slider from '@vueform/slider';
export default {
  components: {
    Slider,
  },
  props: {},

  setup() {
    const store = useFilterBoxShow();
    const filterValues = useFilterValues();
    const result = useResult();
    const router = useRouter();

    const {
      menuColours,
      selectedColours,
      text,
      username,
      quality,
      selectedInfluencer,
      selectedTags,
      selectedBrands,
      dirty,
      colorDict,
    } = storeToRefs(filterValues);

    const { reset } = storeToRefs(store);

    filterValues.fetchColour();

    const checkColorSelected = (items) => {
      return selectedColours.value.includes(items);
    };

    const changeCheckBox = (event, items) => {
      if (event.target.checked) {
        filterValues.addColor(items);
      } else {
        filterValues.removeColor(items);
      }
    };

    const chekDisableButton = ref(true);

    const search = async () => {
      await result.fetchResult();
      router.push('/result');
    };

    const resetButton = () => {
      filterValues.resetAll();
    };

    const subscribeToStore = async () => {
      // Wait until Pinia is initialized
      while (!getActivePinia()) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
      }

      useFilterValues().$subscribe((mutation, state) => {
        const show = useFilterBoxShow();
        if (
          state.text != '' ||
          state.username != '' ||
          state.quality != 50 ||
          state.selectedInfluencer != '' ||
          state.selectedTags != '' ||
          state.selectedBrands != '' ||
          state.selectedColours != ''
        ) {
          show.setReset(true);
        } else {
          show.setReset(false);
        }
      });
    };

    subscribeToStore();

    const setTag = (tag) => {
      filterValues.setSelectedTags([tag]);
    };

    return {
      store,
      menuColours,
      colorDict,
      selectedColours,
      checkColorSelected,
      changeCheckBox,
      text,
      username,
      quality,
      selectedInfluencer,
      selectedTags,
      selectedBrands,
      chekDisableButton,
      search,
      dirty,
      resetButton,
      reset,
      setTag,
    };
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.google.com/specimen/Lato?query=Lato');
@import '@vueform/slider/themes/default.css';
@import './filterbox.scss';
</style>
