import { defineStore } from 'pinia';

const useFilterBoxShow = defineStore('filterbox', {
  state: () => ({
    filter: true,
    tag: false,
    brand: false,
    influencer: false,
    color: false,
    reset: false,
  }),
  getters: {
    showFilter: (state) => state.filter,
    showTag: (state) => state.tag,
    showBrand: (state) => state.brand,
    showInfluencer: (state) => state.influencer,
    showColor: (state) => state.color,
  },
  actions: {
    toggleTag() {
      this.tag = !this.tag;
      this.toggleFilter();
    },
    toggleBrand() {
      this.brand = !this.brand;
      this.toggleFilter();
    },
    toggleInfluencer() {
      this.influencer = !this.influencer;
      this.toggleFilter();
    },
    toggleColor() {
      this.color = !this.color;
      this.toggleFilter();
    },
    toggleFilter() {
      this.filter = !this.filter;
    },
    setReset(value) {
      this.reset = value;
    },
  },
});

export default useFilterBoxShow;
