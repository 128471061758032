<template>
  <div class="main">
    <div class="">
      <div class="row">
        <div class="col">
          <div class="homePoster">
            <div class="img404">
              <img src="../../../assets/404.svg" alt="404 img" />
            </div>
            <div class="textBox">
              <p>No result found, Please try a different search</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import filterBox from '../../filterBox/filterBox.vue'; */
export default {
  components: {
    /* filterBox, */
  },
};
</script>

<style scoped>
@import url('https://fonts.google.com/specimen/Lato?query=Lato');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: auto;
  padding: 0 3rem;
}

.col {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.homePoster {
  width: 100%;
  height: 868px;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
  border: none;
  background: #f7f7f7;
  /* margin: 0 45px; */
}

.img404 {
  width: 100%;
  text-align: center;
}

.img404 img {
  width: 60%;
  height: 550px;
}

.homePoster .textBox {
  width: 100%;
  text-align: center;
}

.homePoster .textBox p {
  /* font-family: "Lato"; */
  width: inherit;
  font-size: 23px;
  color: #b37dea;
}
</style>
