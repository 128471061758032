<template>
  <div class="main">
    <div class="row">
      <div class="col">
        <div class="header-result-page">
          <div class="content">
            <div class="mount-result">
              <p>{{ match }} Influencers Found</p>
            </div>
            <div class="myBtn">
              <!-- <a class="btn btn-primary btn-reset" href="#"> Reset </a> -->
              <button class="btn btn-reset" type="button" v-on:click="backToHome">Reset</button>
              <!-- <router-link class="btn btn-primary btn-reset" to="/">
                Reset
              </router-link> -->
              <a class="btn btn-filter" v-on:click="checkForShowBox">
                <img src="../../../assets/filterVector.svg" alt="filter vector" />
                Show Filter
              </a>
            </div>
          </div>
        </div>
        <div class="showFilter">
          <div class="myCard" :class="{ myCardOnFilter: show_Div_FilterBox }" v-if="match > 0">
            <cardView
              :show_Div_FilterBox="show_Div_FilterBox"
              :result="result"
              :matchTags="matchTags"
            />
          </div>
          <div class="not-found-result" :class="{ myCardOnFilter: show_Div_FilterBox }" v-else>
            <notFoundPage />
          </div>

          <div class="filterBox" v-if="show_Div_FilterBox">
            <div v-if="showFilter">
              <filterBox @showNotFound="ckeckNotFoundResult" />
            </div>
            <div v-else-if="showInfluencer">
              <influencerClasification @inputValue="sendInfluencerValue" />
            </div>
            <div v-else-if="showTag">
              <tagBox @inputValue="sendTags" />
            </div>
            <div v-else-if="showBrand">
              <brandBox @inputValue="sendBrands" />
            </div>
            <div v-else-if="showColor">
              <colorBox @colorValue="sendColor" />
            </div>
          </div>
        </div>
        <div class="pagination" v-if="showPagination">
          <div class="content">
            <pagination @newOffset="newOffsett" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import cardView from './cardView.vue';
import filterBox from '@/components/filterBox/filterBox.vue';
import { ref } from 'vue';
import influencerClasification from '../../filterBox/influencerClasification/influencerClasification.vue';
import tagBox from '../../filterBox/tagBox/tagBox.vue';
import brandBox from '../../filterBox/brandBox/brandBox.vue';
import colorBox from '../../filterBox/colorBox/colorBox.vue';
import notFoundPage from '../notFoundPage/notFoundPage.vue';
import useFilterBoxShow from '@/store/filterbox';
import useResult from '@/store/result';
import pagination from '@/components/pages/showResult/pagination/resultPagination.vue';
import useFilterValues from '@/store/filterValues';

import { useRouter } from 'vue-router';
export default {
  name: 'resultPageHeader',
  components: {
    cardView,
    filterBox,
    influencerClasification,
    tagBox,
    brandBox,
    colorBox,
    notFoundPage,
    pagination,
  },

  setup() {
    const router = useRouter();
    const filterValues = useFilterValues();

    const show_Div_FilterBox = ref(true);

    const store = useFilterBoxShow();
    const { showFilter, showTag, showBrand, showInfluencer, showColor } = storeToRefs(store);

    const result = useResult();

    const { data, count } = storeToRefs(result);
    console.log(data);

    // condition for showing pagination
    const showPagination = ref(true);

    const showNotFoundResult = ref();

    const validate_for_props = ref(false);

    const checkForShowBox = () => {
      var result = show_Div_FilterBox.value ? false : true;
      show_Div_FilterBox.value = result;
    };

    const showFilterAndPosterBox = ref(false);
    const show_filter_poster_box = (input) => {
      showFilterAndPosterBox.value = input;
    };

    const backToHome = () => {
      filterValues.resetAll();
      router.push('/');
    };
    return {
      showFilter,
      showColor,
      showBrand,
      showInfluencer,
      showTag,
      show_Div_FilterBox,
      result: data,
      countMatch: count,
      match: count,
      showPagination,
      showNotFoundResult,
      validate_for_props,
      checkForShowBox,
      show_filter_poster_box,
      backToHome,
    };
  },
};
</script>

<style scoped lang="scss">
@import './resultPage.scss';
</style>
