import http from '@/services';
import useFilterValues from '@/store/filterValues';
import useResult from '@/store/result';
import { storeToRefs } from 'pinia';

export const search = (pageNumber) => {
  const filterValues = useFilterValues();
  const { text, username, quality } = storeToRefs(filterValues);

  const result = useResult();
  const { limit } = storeToRefs(result);

  const params = {
    limit: limit.value,
    offset: pageNumber * limit.value,
    score: quality.value,
    logo: filterValues.getSelectedBrands,
    color: filterValues.getSelectedColour,
    tag: filterValues.getSelectedTags,
    username: username.value,
    query: text.value,
    classification: filterValues.getSelectedInfluencer,
  };
  console.log(params);

  return http.get('search', {
    params: params,
  });
};
