<template>
  <div class="pagination-container">
    <paginate
      :page-count="pageCount"
      :page-range="3"
      :margin-pages="2"
      :click-handler="click"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination'"
      :page-class="'page-item'"
    >
    </paginate>
  </div>
</template>

<script>
import useResult from '@/store/result';
import { storeToRefs } from 'pinia';
import Paginate from 'vuejs-paginate-next';
export default {
  components: {
    paginate: Paginate,
  },
  setup() {
    const result = useResult();
    const { pageCount } = storeToRefs(result);

    const click = (pageNum) => {
      console.log('page number is ', pageNum);
      result.fetchResult(pageNum);
    };

    return {
      click,
      pageCount,
    };
  },
};
</script>
<style lang="css">
.pagination > li > a,
.pagination > li {
  color: #9e55e9;
  background-color: white;
  /* border-radius: 20px; */
}

.pagination > li > a,
.pagination > li > span:hover {
  color: #9e55e9 !important;
  background-color: white;
  /* border-radius: 20px; */
}

.page-item.active > a {
  color: white !important;
  text-decoration: none;
  background-color: #9e55e9 !important;
  border: 1px solid #9e55e9;
}
</style>
