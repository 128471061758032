<template>
  <div class="main">
    <div class="">
      <div class="row">
        <div class="col">
          <div class="bergerNav">
            <mobileSizeHeader />
          </div>
          <div class="content-oracel">
            <h1>Content Oracel</h1>
            <p>You can click on exapmle images, upload a photo or paste a URL of an image</p>
          </div>
          <div class="mobileSizeBtn">
            <mobileSizeBtn @show_box="show_filter_poster_box" />
          </div>
          <div class="homePoster" :class="{ 'show-poster-box': !showFilterAndPosterBox }">
            <div>
              <div class="textBox">
                <h1>Try it!</h1>
                <p>
                  Our influencer search engine uses the latest data science technology to provide
                  the best possible results. We use our human-inspired Visual Search Engine to
                  analyse image content and provide superior results, allowing you to quickly and
                  accurately identify the right influencers for your marketing activation.
                </p>
              </div>
              <div class="imgBox">
                <div class="left-img">
                  <img src="../../../assets/Frame-1.svg" alt="instagram pages" />
                </div>
                <div class="right-img">
                  <div>
                    <h1 class="mb-4"></h1>
                    <p></p>
                  </div>
                  <div>
                    <img src="../../../assets/Frame-2.svg" alt="instagram pages" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="filterBox"
            v-if="showFilter"
            :class="{ 'show-filter-box': !showFilterAndPosterBox }"
          >
            <filterBox @showNotFound="ckeckNotFoundResult" />
          </div>
          <div
            class="filterBox"
            v-else-if="showInfluencer"
            :class="{ 'show-filter-box': !showFilterAndPosterBox }"
          >
            <influencerClasification @inputValue="sendInfluencerValue" />
          </div>
          <div
            class="filterBox"
            v-else-if="showTag"
            :class="{ 'show-filter-box': !showFilterAndPosterBox }"
          >
            <tagBox @inputValue="sendTags" />
          </div>
          <div
            class="filterBox"
            v-else-if="showBrand"
            :class="{ 'show-filter-box': !showFilterAndPosterBox }"
          >
            <brandBox @inputValue="sendBrands" />
          </div>
          <div
            class="filterBox"
            v-else-if="showColor"
            :class="{ 'show-filter-box': !showFilterAndPosterBox }"
          >
            <colorBox @colorValue="sendColor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import filterBox from '../../filterBox/filterBox.vue';
import mobileSizeBtn from './mobileSize/mobileSizeBtn.vue';
import mobileSizeHeader from './mobileSize/mobileSizeHeader.vue';
import influencerClasification from '../../filterBox/influencerClasification/influencerClasification.vue';
import tagBox from '../../filterBox/tagBox/tagBox.vue';
import brandBox from '../../filterBox/brandBox/brandBox.vue';
import colorBox from '../../filterBox/colorBox/colorBox.vue';
import useFilterBoxShow from '@/store/filterbox';
// import router from "@/router";

export default {
  components: {
    filterBox,
    mobileSizeBtn,
    mobileSizeHeader,
    tagBox,
    influencerClasification,
    brandBox,
    colorBox,
  },

  setup() {
    const store = useFilterBoxShow();
    const { showFilter, showTag, showBrand, showInfluencer, showColor } = storeToRefs(store);

    const showFilterAndPosterBox = ref(false);
    const show_filter_poster_box = (input) => {
      showFilterAndPosterBox.value = input;
    };

    return {
      showFilter,
      showColor,
      showBrand,
      showInfluencer,
      showTag,
      /////
      show_filter_poster_box,
      showFilterAndPosterBox,
    };
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.google.com/specimen/Lato?query=Lato');
@import './HomePage.scss';
</style>
