import { createApp } from 'vue';
import App from './App.vue';

import router from './router.js';
import 'vueperslides/dist/vueperslides.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createPinia } from 'pinia';
// import the package

// import the necessary css file
import Paginate from 'vuejs-paginate-next';

const app = createApp(App);
const piana = createPinia();

app.use(piana);
app.use(router);
app.use(Paginate);
app.mount('#app');
