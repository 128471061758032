<template>
  <div class="headerOfPage">
    <HeaderOfPage />
    <router-view />
  </div>
</template>

<script>
import HeaderOfPage from './components/HeaderOfPage/HeaderOfPage.vue';

export default {
  name: 'App',
  components: {
    HeaderOfPage,
  },
};
</script>

<style>
@media (max-width: 576px) {
  .headerOfPage {
    display: none;
  }
}
@import '@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css';

.form-control {
  padding: 0.375rem 0.75rem !important;
}
</style>
