import { defineStore } from 'pinia';
import { search } from '../services/search';

const useResult = defineStore('result', {
  state: () => ({
    data: [],
    count: 0,
    offset: 0,
    pageCount: 0,
    limit: 9,
    pageNumber: 0,
  }),
  getters: {
    getData: (state) => state.data,
    getCount: (state) => state.count,
  },
  actions: {
    async fetchResult(pageNum = 0) {
      this.data = [];
      try {
        var result = await search(pageNum);
        this.data = result.data.data;
        this.count = result.data.pagination.total;
        console.log(this.count)

        this.pageCount = Math.floor(this.count / this.limit);
        this.pageNumber = pageNum;
      } catch (e) {
        /* handle error */
        console.log(e);
        this.data = [];
        this.count = 0;
        this.pageCount = 0;
        this.pageNumber = 0;
      }
    },
  },
});

export default useResult;
