<template>
  <div class="main">
    <div class="row">
      <div class="col">
        <div class="form mb-4">
          <a class="form-control btn" v-on:click="ckeck_show_filter_box">
            <img src="../../../../assets/search.png" alt="search icon" />
            Search</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup(props, { emit }) {
    const show_filter_box_onMobile = ref([false]);
    // const show_poster_box_onMobile = ref(true);

    // cheack conditions for showing filter box and home poster
    const ckeck_show_filter_box = () => {
      var result = !show_filter_box_onMobile.value ? true : false;
      show_filter_box_onMobile.value = result;
      emit("show_box", result);
    };

    return { show_filter_box_onMobile, ckeck_show_filter_box };
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.form {
  position: relative;
  /* padding: 1rem 0.75rem 0 0.75rem; */
}

.form img {
  width: 6%;
  color: #ffffff;
}

.form a {
  text-decoration: none;
  color: #ffffff;
  font-size: 17px;
}

.btn {
  border: 1px solid #b37dea;
  border-radius: 16px;
  height: 50px;
  text-indent: 33px;
  transition: all 0.2s linear;
  background: #b37dea;
  text-align: center;
  line-height: 33px;
}

.btn:hover {
  background: #9e55e9;
}

.btn:focus {
  box-shadow: none;
  background: #4e1587;
}

.btn:active {
  background: #4e1587;
}
</style>
