<template>
  <div class="main">
    <div class="">
      <div class="row">
        <div class="col">
          <div class="brandBox">
            <form>
              <div class="brandPageHeader mb-3">
                <a href="#" v-on:click="store.toggleInfluencer">
                  <img src="../../../assets/Vector-revers.svg" alt="vector" />
                </a>
                <p>Influencer Classification</p>
                <!-- <a href="#">Reset</a> -->
                <input type="reset" value="Reset" class="resetBtn" v-on:click="resetButton" />
              </div>
              <div class="mySearchBox">
                <!-- <SearchBox :axx="axx" /> -->
                <div class="form mb-4">
                  <span><img src="../../../assets/search.png" alt="search icon" /></span>
                  <input
                    type="text"
                    class="form-control form-input"
                    placeholder="Search"
                    v-model="searchInfluencer"
                    v-on:keyup="searchFunction"
                  />
                </div>
              </div>
              <div class="list-content">
                <div>
                  <div class="myList" v-for="(item, index) in influencer" :key="index">
                    <div class="captal-lether" v-for="(i, index) in item" :key="index">
                      <p>{{ i[0][0].toUpperCase() }}</p>
                    </div>
                    <div class="form-check">
                      <div>
                        <label class="form-check-label" v-for="(items, index) in item" :key="index">
                          {{ items }}
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="items"
                            v-model="checkboxValue"
                            v-on:change="setSearchBoxValue"
                          />
                        </label>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="myBtn">
                <button
                  type="button"
                  class="btn btn-apply"
                  :disabled="checkboxValue.length == 0"
                  :class="{ 'btn-apply-disabled': checkboxValue.length == 0 }"
                  v-on:click="sendChechBoxValue"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import useFilterBoxShow from '@/store/filterbox';
import useFilterValues from '@/store/filterValues';

export default {
  setup() {
    const store = useFilterBoxShow();
    const filterValues = useFilterValues();

    const searchInfluencer = ref('');
    const checkboxValue = ref([]);

    filterValues.fetchInfluencer();

    const resetButton = () => {
      checkboxValue.value = [];
      filterValues.resetSelectedInfluencer();
    };

    const searchFunction = () => {
      filterValues.searchOnInfluencer(searchInfluencer.value);
    };

    const sendChechBoxValue = () => {
      filterValues.setSelectedInfluencer(checkboxValue.value);
      store.toggleInfluencer();
    };

    return {
      store,
      influencer: computed(() => filterValues.influencer),
      searchFunction,
      checkboxValue,
      resetButton,
      searchInfluencer,
      sendChechBoxValue,
    };
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.google.com/specimen/Lato?query=Lato');
@import './influencerClasification.scss';
</style>
