<template>
  <div class="main">
    <div>
      <div class="row">
        <div class="col-12">
          <ul>
            <li><a href="#">Content Oracle</a></li>
            <li><a href="#">Api</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import url('https://fonts.google.com/specimen/Lato?query=Lato');
@import './HeaderOfPage.scss';
</style>
