<template>
  <div class="main">
    <div class="">
      <div class="row">
        <div class="col">
          <div class="brandBox">
            <form>
              <div class="brandPageHeader mb-3">
                <a href="#" v-on:click="store.toggleColor">
                  <img src="../../../assets/Vector-revers.svg" alt="vector" />
                </a>
                <p>Color</p>
                <!-- <a href="#">Reset</a> -->
                <input type="reset" class="resetBtn" value="Reset" v-on:click="resetButton" />
              </div>
              <div class="colorBox">
                <color-picker v-bind="color" @input="onInput"></color-picker>
                <!-- <colorPicker v-model="color" /> -->
              </div>
              <div class="mySearchBox">
                <!-- <SearchBox /> -->
                <div class="form mb-4">
                  <span><img src="../../../assets/search.png" alt="search icon" /></span>
                  <input
                    type="text"
                    class="form-control form-input"
                    placeholder="Search"
                    v-model="searchColour"
                    v-on:keyup="searchFunction"
                  />
                </div>
              </div>
              <div class="list-content">
                <div>
                  <div class="myList" v-for="(item, index) in colours" :key="index">
                    <div class="captal-lether" v-for="(i, index) in item" :key="index">
                      <p>{{ i[0][0].toUpperCase() }}</p>
                    </div>
                    <div class="form-check">
                      <div>
                        <label class="form-check-label" v-for="(items, index) in item" :key="index">
                          {{ items }}
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="items"
                            v-model="checkboxValue"
                            v-on:change="setSearchBoxValue"
                          />
                        </label>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="myBtn">
                <button
                  type="button"
                  class="btn btn-apply"
                  :disabled="checkboxValue.length == 0 && searchColour.length == 0"
                  :class="{
                    'btn-apply-disabled': checkboxValue.length == 0 && searchColour.length == 0,
                  }"
                  v-on:click="sendChechBoxValue"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive } from 'vue';
import useFilterBoxShow from '@/store/filterbox';
import useFilterValues from '@/store/filterValues';
import ColorPicker from '@radial-color-picker/vue-color-picker';

export default {
  components: {
    ColorPicker,
  },
  setup() {
    const store = useFilterBoxShow();
    const filterValues = useFilterValues();

    const searchColour = ref('');
    const checkboxValue = ref([]);

    filterValues.fetchColour();

    const resetButton = () => {
      checkboxValue.value = [];
      filterValues.resetSelectedColours();
    };

    const searchFunction = () => {
      filterValues.searchOnColours(searchColour.value);
    };

    const sendChechBoxValue = () => {
      /* console.log('here'); */
      if (searchColour.value != '') {
        const colour = [];
        colour.push(searchColour.value);
        /* console.log(colour); */
        filterValues.setSelectedColours(colour.concat(checkboxValue.value));
      } else {
        filterValues.setSelectedColours(checkboxValue.value);
      }
      store.toggleColor();
    };

    const color = reactive({
      hue: 50,
      saturation: 100,
      luminosity: 50,
      alpha: 1,
    });

    const convertColour = (h, s = 100, l = 50) => {
      // Must be fractions of 1
      s /= 100;
      l /= 100;

      let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;

      if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
      }
      // Having obtained RGB, convert channels to hex
      r = Math.round((r + m) * 255).toString(16);
      g = Math.round((g + m) * 255).toString(16);
      b = Math.round((b + m) * 255).toString(16);

      // Prepend 0s, if necessary
      if (r.length == 1) r = '0' + r;
      if (g.length == 1) g = '0' + g;
      if (b.length == 1) b = '0' + b;

      return '#' + r + g + b;
    };

    return {
      store,
      colours: computed(() => filterValues.colours),
      searchFunction,
      checkboxValue,
      resetButton,
      searchColour,
      sendChechBoxValue,
      color,
      onInput(hue) {
        searchColour.value = convertColour(hue);
        color.hue = hue;
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.google.com/specimen/Lato?query=Lato');

@import './colorBox.scss';
</style>
