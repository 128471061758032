import axios from 'axios';
// import * as userService from './users';

/*
 * You can customize Axios settings
 * Ver mais em https://github.com/axios/axios
 */

const token =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk0MzQxMzgxLCJpYXQiOjE2NjIyODY5ODEsImp0aSI6IjEzNWVmY2I0ZWI5MTQ3ZjliMzhkNmVkNTMyZTBmYTFhIiwidXNlcl9pZCI6M30.g24Cfc2IAjjSbI6EqTV-3u2S1x4kGYBlfxU2TD1Arjs';

const http = axios.create({
  baseURL: 'https://search.oraclecontent.com/api',
  // baseURL: 'http://127.0.0.1:3000/api',
  withCredentials: false,
  // headers: {
  //   Authorization: 'Bearer ' + token,
  //   accept: 'application/json',
  // },
});

// Exports Axios object to be used by the services
export default http;

// Exports the services so they can be used by Vuex's actions
// export { userService };
