<template>
  <div class="main">
    <div class="row">
      <div class="col">
        <div class="contetnt">
          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
              <a class="navbar-brand me-auto" href="#"
                ><img src="../../../../assets/orpiva_LOGO.svg" alt="logo"
              /></a>

              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Content Oracel</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Api</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div class="logo"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.navbar {
  width: 100%;
  height: auto;
  padding: 0;
  margin-bottom: 5%;
}

.navbar-brand {
  margin-left: 180px;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-nav {
  text-align: center;
}

.nav-item {
  border-bottom: 1px dotted grey;
}
</style>
